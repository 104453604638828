import React from 'react';
import TextBox from 'devextreme-react/text-box';
import './text-box.scss';

const TextBox1 =  ({ onTbvalueChange, value, displayValue, disabled, caption, stylingMode, placeholder, displayStar }) => {

    function tb_onChangedHandler(event){
        onTbvalueChange(event.value);
    }

    return (
        <div>
            <div className="dx-fieldset-text">
                <div className="dx-field">
                    <div className="dx-field-label"> 
                        {displayStar ? ( <span style={{color: 'red'}}>* </span> ) : ('')}
                        <span>{caption}</span>
                        {displayValue == ''? <span className="required-field">Required</span>: null }
                    </div>
                </div>
            </div>  
            <div className="dx-fieldset">
                <div className="dx-field">
                    <div className="dx-field-value" >
                        <TextBox 
                            className  ="text-box-1"
                            value      ={value}
                            readOnly   ={disabled}
                            placeholder={placeholder}
                            onValueChanged={tb_onChangedHandler}
                            stylingMode={stylingMode}>
                        </TextBox>
                    </div>
                </div>
            </div>  
        </div>
    );
}

export default TextBox1;