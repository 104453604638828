import React from 'react';
import Cookies from 'universal-cookie';
import Form from 'devextreme-react/form';
import { Item } from 'devextreme-react/select-box';
import { DefaultPager, DefaultStateStoringIgnore } from '../../../utils/default-data-grid-settings';
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    SearchPanel,
    ColumnChooser,
    Export,
    Scrolling,
    Summary,
    TotalItem,
    Grouping,
    GroupPanel,
    LoadPanel,
    StateStoring
} from 'devextreme-react/data-grid';
import './audit-log.scss';

//components
import { ChildShowFilterRow } from '../../../components/index';
import { ListingHeader6 } from '../../../layouts/index';
import Flash from '../../../components/message/flash';
import DownloadPopUp from '../../../components/pop-up/download-popup';
import ErrorPopUp from '../../../components/pop-up/error-popup';

//default 
import { DefaultSMIAuditLog } from '../../../utils/default-smi';
import { CompanyID, UserID, ClientID, AdminYN, SetCookies, SetDecimalPoints, SetDefaultName, DefaultName, Token, SetCompanyID } from '../../../utils/default-cookies';

//language
import { formatMessage } from 'devextreme/localization';

//loading bar
import loadingBar from '../../../components/image/loadingBar.gif';

//api
import { GetAllCompaniesIAGByUser, GetAllUser, GetAuditLogSetting, GetAuditLogSettingCount, GetAllDocumentType, GetAllSetting } from './audit-log-service'
import { PostUserLayout, GetUserLayout } from '../../../api/user-preferred-layout';
import { GetUserGroupAccessRightsByModuleItem } from '../package-item/package-item-services';

//export to excel
import 'jspdf-autotable';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { getReactParameters } from '../../../utils/iframe-func';

var moduleItemID = 0;
const cookies = new Cookies();
const Mousetrap = require("mousetrap");
Mousetrap.prototype.stopCallback = function () {
    return false;
}

class AuditLog extends React.Component {
    constructor(props) {
        super(props);

        //component instance
        this.dataGridRef = React.createRef();
        this.childToolBarRef = React.createRef();
        this.childShowFilterRowRef = React.createRef();
        this.childHeader = React.createRef();
        this.addMenuItems = this.addMenuItems.bind(this);

        this.searchValueChangeHandler = this.searchValueChangeHandler.bind(this);
        this.selectValueChangeHandler = this.selectValueChangeHandler.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.onSelectDateCallBack = this.onSelectDateCallBack.bind(this);
        this.setTagBoxValue = this.setTagBoxValue.bind(this);
        this.onSelectUserIDCallBack = this.onSelectUserIDCallBack.bind(this);
        this.onFocusedRowChanged = this.onFocusedRowChanged.bind(this);
        this.onValueChange = this.onValueChange.bind(this);

        this.onCellPrepared = this.onCellPrepared.bind(this);
        this.onFlashMessageTimer = this.onFlashMessageTimer.bind(this);
        this.calculateSearchExpression = this.calculateSearchExpression.bind(this);
        this.dataGridFocusedRowUp = this.dataGridFocusedRowUp.bind(this);
        this.dataGridFocusedRowDown = this.dataGridFocusedRowDown.bind(this);
        this.closeFlashMessageCallback = this.closeFlashMessageCallback.bind(this);
        this.onCellRender = this.onCellRender.bind(this);

        this.handleDownloadConfirmationDialog = this.handleDownloadConfirmationDialog.bind(this);
        this.handleDownloadPopUpCallBack = this.handleDownloadPopUpCallBack.bind(this);
        this.handleSessionPopUpCallBack = this.handleSessionPopUpCallBack.bind(this);
        this.detectPressKey = this.detectPressKey.bind(this);

        this.restoreLayout = this.restoreLayout.bind(this);
        this.showMsgHandler = this.showMsgHandler.bind(this);
        this.showFilterRow = this.showFilterRow.bind(this);
        this.showGroupPanel = this.showGroupPanel.bind(this);
        this.showInfoChange = this.showInfoChange.bind(this);

        this.GetFormattedDate = this.GetFormattedDate.bind(this);
        this.receiveMessage = this.receiveMessage.bind(this);
        this.fetchDataAPI = this.fetchDataAPI.bind(this);

        this.state = {
            companies: [],
            selectedUserID: [],
            defaultDateFormat: '',
            userID: [],
            defaultUsername: [],
            parentItemCode: "",
            auditLogSetting: [],
            docType: [],
            allSetting: [],
            searchText: '',
            showFilterRow: false,
            showGroupPanel: false,
            allowSave: false,
            allowDelete: false,
            allowCopy: false,
            allowAdd: false,
            viewDtl: false,
            buttonMode: true,
            displayFlashMessageDialog: false,
            status: 'success',
            size: 'flash-message-success-container',
            fromDate: '',
            toDate: '',
            allowExportGrid: false,
            allowDisplayColumnChooser: false,
            allowRestoreLayout: false,
            allowSaveLayout: false,
            displayDownloadPopUp: false,
            displaySessionPopUp: false,
            isDeleteBtnFocus: true
        }
    }

    //set child - data-grid-context-menu to show group panel
    showGroupPanel() {
        this.setState(prevState => ({
            showGroupPanel: !prevState.showGroupPanel
        }));
    }

    //set child - data-grid-context-menu to show filter row
    showFilterRow() {
        this.setState(prevState => ({
            showFilterRow: !prevState.showFilterRow
        }));
        this.dataGridRef.current.instance.clearFilter();
    }

    closeFlashMessageCallback = () => {
        this.setState(state => ({
            displayFlashMessageDialog: !state.displayFlashMessageDialog
        }))
    }

    saveState(state) {
        //every time refresh will call 1 time due to set state after get user preferred layout api called.
        //unable to call this api before componentdidmount due to have to wait for the cookies send from iframe 
        //save the custom state 
        PostUserLayout(Token(), CompanyID(), ClientID(), UserID(), moduleItemID, DefaultSMIAuditLog.controlID, DefaultSMIAuditLog.auditLog, "storageKey", state);
        //PostUserLayout("eyJhbGciOiJSUzI1NiIsImtpZCI6IjQyMEU5NjI2QzYxNTY1NEQyRDUzNjlBMEFBNTc3OEFBIiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2Mjk2MjM0NDIsImV4cCI6MTYyOTcwOTg0MiwiaXNzIjoiaHR0cHM6Ly90ZXN0YXBpLmlyc2FsYXlhLmNvbSIsImNsaWVudF9pZCI6ImFwaSIsInN1YiI6ImFkbWluIiwiYXV0aF90aW1lIjoxNjI5NjIzNDQyLCJpZHAiOiJsb2NhbCIsIkxvZ2luQXMiOiJVc2VyIiwiQ2xpZW50SUQiOiJyZWFjdCIsIkNuc05hbWUiOiJBbGF5YURiRXhhYnl0ZSIsIkRiTmFtZSI6IklSU0FjY291bnRpbmdfSVJTMDEwNF9SRUFDVCIsImp0aSI6IkQwQ0UzNUIyMTE4ODZGNUFCOTZGOTQzMDIxQkNCRDM3IiwiaWF0IjoxNjI5NjIzNDQyLCJzY29wZSI6WyJhcGkxIiwib2ZmbGluZV9hY2Nlc3MiXSwiYW1yIjpbInBhc3N3b3JkIl19.jfzeMIJtwkArN_JyoYIL4r79hyu2T_t7l81wrbMfmUrnP2ds9EsvX_nlHlGbizunM0N8z6uvAUNu8kuUE_U4ptpOWuCZFihL8kzbWuc1OM6RBF0q4X9AkRk9sURrq1q1fA0WCsST_SV5IW6WhMNx2_3TiQZ_tggNhAznaXT1TINU8MuCYNiMSobY4_a5I0dZdRmL9OTZgZbVmT48G1zjLSgxu3kS5zKdRssX1FWe5i1uDbqU2QDSrgxJdvoaq-ICuZAdOUg1TbehJ8tx-336zywLSvl9n-1l0l9zetmG6f-njRytnFdqqJHg5FpmODM_XbcSVI0nzF8sOzAZdcrEDg", 392, 1, 2, moduleItemID, DefaultSMIPackageItem.controlID,  DefaultSMIPackageItem.packageItem, "storageKey", state);
    }

    showInfoChange(value) {
        this.setState({ ...this.state, showInfo: value })
    }

    handleSessionPopUpCallBack = () => {
        this.setState(state => ({
            displaySessionPopUp: !state.displaySessionPopUp
        }))
    }

    handleDownloadPopUpCallBack = () => {
        this.getAuditLogExcel();
    }

    //get audit log excel data
    async getAuditLogExcel() {
        var auditLogList = await Promise.all([GetAuditLogSetting(Token(), CompanyID(), this.state.selectedUserID, this.state.parentItemCode, this.state.fromDate, this.state.toDate)]);
        var result = auditLogList[0];

        for (var i = 0; i < result.length; i++) {
            result[i].LogDatetime = this.formatDateTime(result[i].LogDatetime);
        }

        const newArray = result.map(item => {
            return {
                "Date & Time": item.LogDatetime,
                Code: item.Code,
                "Event Type": item.EventType,
                "Column Name": item.ColumnName,
                Description: item.Description,
                "User ID": item.UserID
            };
        });

        this.exportGridXLSX(newArray, "Package-Item");
        this.handleDownloadConfirmationDialog();
    }

    //get audit log list
    async getAuditLogList() {
        var auditLogList = await Promise.all([GetAuditLogSetting(Token(), CompanyID(), this.state.selectedUserID, this.state.parentItemCode, this.state.fromDate, this.state.toDate)]);
        var result = auditLogList[0];

        for (var i = 0; i < result.length; i++) {
            result[i].LogDatetime = this.formatDateTime(result[i].LogDatetime);
        }

        this.setState({ auditLogSetting: result });
    }

    exportGridXLSX = (csvData, fileName) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        var today = new Date();

        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + '-' + today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + fileExtension);

    }

    handleDownloadConfirmationDialog = () => {
        this.setState(state => ({
            displayDownloadPopUp: !state.displayDownloadPopUp
        }))
    }

    handleCompanies = (result) => {
        this.setState({ companies: result });
    }

    handleUserID = (result) => {
        var name = [];

        var data = result.find(x => x.US_ID == UserID());
        name.push(data.US_UserID)
        this.setState({ userID: result, defaultUsername: name, defaultDateFormat: data.US_DateFormat });
    }

    handleAuditLogSetting = (result) => {
        if (result.Total > 500) {
            this.handleDownloadConfirmationDialog();
        }
        else {
            this.getAuditLogList();
        }
    }

    handleAllowRestoreLayout = (result) => {
        this.setState({ allowRestoreLayout: result });
    }

    handleAllowSaveLayout = (result) => {
        this.setState({ allowSaveLayout: result })
    }

    handleAllowExportGrid = (result) => {
        this.setState({ allowExportGrid: result });
    }

    handleAllowDisplayColumnChooser = (result) => {
        this.setState({ allowDisplayColumnChooser: result });
    }

    detectPressKey(event) {
        if (event.keyCode === 9) {
            this.setState(state => ({
                isDeleteBtnFocus: !state.isDeleteBtnFocus
            }))
        }
    }

    formatDateTime = (result) => {
        var date = new Date(result);
        var currentMonth = date.getMonth() + 1;
        var dateFormat = '';

        if (this.state.defaultDateFormat == '{dd}/{MM}/{yyyy}') {
            dateFormat = (date.getDate().toString().length == 1 ? "0" : '') + date.getDate() + '/' + (currentMonth.toString().length == 1 ? "0" : '') + currentMonth + "/" + date.getFullYear()

        } else if (this.state.defaultDateFormat == '{dd}/{yyyy}/{MM}') {
            dateFormat = (date.getDate().toString().length == 1 ? "0" : '') + date.getDate() + "/" + date.getFullYear() + '/' + (currentMonth.toString().length == 1 ? "0" : '') + currentMonth

        } else if (this.state.defaultDateFormat == '{MM}/{dd}/{yyyy}') {
            dateFormat = (currentMonth.toString().length == 1 ? "0" : '') + currentMonth + "/" + (date.getDate().toString().length == 1 ? "0" : '') + date.getDate() + '/' + date.getFullYear()

        } else if (this.state.defaultDateFormat == '{MM}/{yyyy}/{dd}') {
            dateFormat = (currentMonth.toString().length == 1 ? "0" : '') + currentMonth + "/" + date.getFullYear() + '/' + (date.getDate().toString().length == 1 ? "0" : '') + date.getDate()

        } else if (this.state.defaultDateFormat == '{yyyy}/{dd}/{MM}') {
            dateFormat = date.getFullYear() + '/' + (date.getDate().toString().length == 1 ? "0" : '') + date.getDate() + '/' + (currentMonth.toString().length == 1 ? "0" : '') + currentMonth

        } else if (this.state.defaultDateFormat == '{yyyy}/{MM}/{dd}') {
            dateFormat = date.getFullYear() + '/' + (currentMonth.toString().length == 1 ? "0" : '') + currentMonth + '/' + (date.getDate().toString().length == 1 ? "0" : '') + date.getDate()

        }
        var hours = (date.getHours().toString().length == 1 ? '0' : '') + "" + (date.getHours());
        var minuts = ((date.getMinutes()).toString().length == 1 ? '0' : '') + "" + (date.getMinutes());
        var formattedDate = dateFormat + ' ' + hours + ':' + minuts
        return formattedDate;
    }

    handleDocType = (result) => {
        this.setState({ docType: result });
    }

    handleAllSetting = (result) => {
        this.setState({ allSetting: result });
    }

    geturlInfo(e) {
        const location = e?.currentTarget?.location?.href || window.location.href;
        var parentInfo = (new URL(location.replace('#', ''))).searchParams;

        var parentItemCode = parentInfo.get('id');
        var parentCompanyID = parentInfo.get('companyID');

        if (parentCompanyID != null) {
            SetCompanyID(cookies, parentCompanyID);
        }

        var today = this.GetFormattedDate(new Date()) + "T23:59:00";

        var default7Days = new Date();
        default7Days.setDate(default7Days.getDate() - 7);
        default7Days = this.GetFormattedDate(default7Days) + "T00:00:00";

        this.setState({
            fromDate: default7Days,
            toDate: today,
            selectedUserID: UserID(),
            parentItemCode: parentItemCode
        })
    }

    componentDidMount() {
        window.addEventListener("message", this.receiveMessage, false);
        document.addEventListener('keydown', this.onFlashMessageTimer, false);
        document.addEventListener("keydown", this.detectPressKey, false);
        document.addEventListener('mousedown', this.onFlashMessageTimer, false);

        Mousetrap.bind("up", this.dataGridFocusedRowUp);
        Mousetrap.bind("down", this.dataGridFocusedRowDown);

        if (Token()) {
            this.geturlInfo();
            this.fetchDataAPI();
        } else {
            // prompt Alaya 1.0 to re-postMessage for session data after receiveMessage eventListener is registered
            // to handle issue where initial postMessage is sent before eventListener is registered (iOS and macOS)
            window.parent.postMessage(getReactParameters(), "*");
        }
    }

    componentWillUnmount() {
        //remove event listener
        window.removeEventListener("message", this.receiveMessage, false);
        document.removeEventListener('keydown', this.onFlashMessageTimer, false);
        document.removeEventListener("keydown", this.detectPressKey, false);
        document.removeEventListener('mousedown', this.onFlashMessageTimer, false);

        Mousetrap.unbind("up");
        Mousetrap.unbind("down");
    }

    async receiveMessage(event) {
        if (event?.data?.accessToken && !Token()) {
            SetCookies(cookies, event.data.accessToken, event.data.userID, event.data.companyID, event.data.clientID, event.data.username, event.data.adminYN, event.data.administratorYN, event.data.language);

            // to get id from url, searchParams not working when got hashtag in url
            this.geturlInfo(event);

            // fetch API
            this.fetchDataAPI();
        }
    }

    async fetchDataAPI() {
        const [dataSource_GetAllCompanyIAG, dataSource_User, auditLogSetting_Count, dataSource_DocType, dataSource_AllSetting, UserPreferedLayout, UserAccessControl] = await Promise.all([
            GetAllCompaniesIAGByUser(Token(), ClientID(), UserID(), AdminYN()),
            GetAllUser(Token(), UserID()),
            GetAuditLogSettingCount(Token(), CompanyID(), UserID(), this.state.parentItemCode, this.state.fromDate, this.state.toDate),
            GetAllDocumentType(Token()),
            GetAllSetting(Token()),
            GetUserLayout(Token(), UserID(), DefaultSMIAuditLog.auditLog, DefaultSMIAuditLog.controlID),
            GetUserGroupAccessRightsByModuleItem(Token(), UserID(), DefaultSMIAuditLog.auditLog, CompanyID())
        ])

        if (dataSource_GetAllCompanyIAG == 'Error: 401' || dataSource_User == 'Error: 401') {
            this.handleSessionPopUpCallBack();
        }
        else {
            var currentCompanyData = dataSource_GetAllCompanyIAG.find(x => x.CO_ID == CompanyID());
            SetDefaultName(cookies, currentCompanyData.CO_Name);
            SetDecimalPoints(cookies, currentCompanyData.IAG_ItemRounding);

            this.handleCompanies(dataSource_GetAllCompanyIAG);
            this.handleUserID(dataSource_User);
            this.handleAuditLogSetting(auditLogSetting_Count);
            this.handleDocType(dataSource_DocType);
            this.handleAllSetting(dataSource_AllSetting);

            if (AdminYN() == "true") {
                this.handleAllowDisplayColumnChooser(true);
                this.handleAllowExportGrid(true);
                this.handleAllowRestoreLayout(true);
                this.handleAllowSaveLayout(true);
            }
            else {
                this.handleAllowDisplayColumnChooser(UserAccessControl.Query_UserModuleItemAccessSimplified[0].GridColumnChooser);
                this.handleAllowExportGrid(UserAccessControl.Query_UserModuleItemAccessSimplified[0].ExportGrid);
                this.handleAllowRestoreLayout(UserAccessControl.Query_UserModuleItemAccessSimplified[0].RestoreGridLayout);
                this.handleAllowSaveLayout(UserAccessControl.Query_UserModuleItemAccessSimplified[0].SaveGridLayout);
            }

            if (UserPreferedLayout.length > 0) {
                moduleItemID = UserPreferedLayout[0].UPL_ModuleItemID;
                if (this.dataGridRef.current != null) {
                    var userlayout = (JSON.parse(UserPreferedLayout[0].UPL_Layout));
                    userlayout['pageIndex'] = 0;
                    delete userlayout['focusedRowKey'];
                    this.dataGridRef.current.instance.state(userlayout);
                }
            }
        }
    }

    searchValueChangeHandler = (event) => {
        this.setState({ searchText: event });
    }

    selectValueChangeHandler = (event) => {
        SetCompanyID(cookies, event);
    }

    onSelectUserIDCallBack = (value) => {
        var valueString = JSON.stringify(value).replace(/]|[[]/g, '');

        this.setState({ selectedUserID: valueString });
    }

    onValueChange = (value) => {
        this.setState({ parentItemCode: value })
    }

    onSelectDateCallBack = (fromDate, toDate) => {
        this.setState({
            fromDate: fromDate,
            toDate: toDate
        })
    }

    setTagBoxValue = (value) => {
        this.setState({ defaultUsername: value })
    }

    GetFormattedDate(date) {
        var month = ("0" + (date.getMonth() + 1)).slice(-2);
        var day = ("0" + (date.getDate())).slice(-2);
        var year = date.getFullYear();
        return year + "-" + month + "-" + day;
    }

    onFilterClick = (event) => {
        this.getCompanyID();
    }

    async getCompanyID() {
        if (this.state.selectedUserID !== "" && this.state.parentItemCode !== "") {
            var result = await Promise.all([GetAuditLogSettingCount(Token(), CompanyID(), this.state.selectedUserID, this.state.parentItemCode, this.state.fromDate, this.state.toDate)]);
            var item = result[0];

            if (item.Total > 500) {
                this.handleDownloadConfirmationDialog();
            }
            else {
                this.getAuditLogList();
            }
        }
    }

    loadState() {
        //return {"columns":[{"visibleIndex":0,"width":60,"visible":true},{"visibleIndex":1,"dataField":"IM_ID","name":"IM_ID","dataType":"number","width":90,"visible":false},{"visibleIndex":3,"dataField":"IM_Code","name":"IM_Code","dataType":"string","width":190,"visible":true},{"visibleIndex":4,"dataField":"IM_Description","name":"IM_Description","dataType":"string","visible":true},{"visibleIndex":2,"dataField":"IM_Description2","name":"IM_Description2","dataType":"string","visible":true},{"visibleIndex":5,"dataField":"IC_Description","name":"IC_Description","dataType":"string","visible":true},{"visibleIndex":6,"dataField":"IG_Description","name":"IG_Description","dataType":"string","visible":true}],"allowedPageSizes":[20,40,60],"filterPanel":{"filterEnabled":true},"filterValue":null,"searchText":"","pageIndex":0,"pageSize":20,"focusedRowKey":98580}
    }

    onFocusedRowChanged(e) {
        const dataRow = e.row && e.row.data;
        //this.setState({ auditLogSetting: dataRow });
    }

    dataGridFocusedRowUp() {
        //up shortcut key not function when focus on data grid
        this.dataGridRef.current.instance.focus();
    }

    dataGridFocusedRowDown() {
        //down shortcut key not function when focus on data grid
        this.dataGridRef.current.instance.focus();
        var newFocusRowIndex = this.dataGridRef.current.instance.option('focusedRowIndex') + 1;
        this.dataGridRef.current.instance.option('focusedRowIndex', newFocusRowIndex)
    }

    onFlashMessageTimer() {
        if (this.state.displayFlashMessageDialog === true) {
            setTimeout(() => this.setState({ displayFlashMessageDialog: false }), 1000)
        }
    }

    //data-grid-context-menu to restore datagrid default layout 
    restoreLayout() {
        PostUserLayout(Token(), CompanyID(), ClientID(), UserID(), moduleItemID, DefaultSMIAuditLog.controlID, DefaultSMIAuditLog.auditLog, "storageKey", " ");
        if (this.dataGridRef.current != null)
            this.dataGridRef.current.instance.state("");
    }

    get dataGrid() {
        // `current.instance` points to the UI component instance
        return this.dataGridRef.current.instance;
    }

    showMsgHandler(params, value, size) {
        if (params !== '') {
            this.setState({ emptyMsg: params, displayFlashMessageDialog: true, status: value, size: size });
        }
        else {
            this.setState({ emptyMsg: '', displayFlashMessageDialog: false, status: value, size: size });
        }
    }

    onCellPrepared(e) {
        if (e.rowType === "totalFooter") {
            if (e.column.type === "buttons") {
                const summaryValue = e.component.getTotalSummaryValue("totalCount");
                // create the required HTML element with 'summaryValue' and append it to 'e.cellElement'
                const span = document.createElement("span");
                span.className = "dx-datagrid-summary-item"
                span.innerHTML = formatMessage("Records") + ": " + summaryValue;
                e.cellElement.appendChild(span);
            }
            if (e.column.dataField === "A") {
                e.cellElement.innerHTML = "";
            }
        }
        if (e.rowType === "totalAmount" && e.summaryItems.length) {
            e.cellElement.innerText = "Sum for " + e.data.key + ": $" + e.summaryItems[0].value;
        }
    }

    onOptionChanged = (e) => {
        if (e.fullName === "columns[0].sortOrder") {
            e.component.option("focusedRowEnabled", false);
            e.component.option("focusedRowIndex", null);
        }
    }

    onContentReady = (e) => {
        e.component.option("focusedRowEnabled", true)
        e.component.option("focusedRowKey", e.component.getKeyByRowIndex(0));
        e.component.option("focusedRowIndex", 0);
    }

    addMenuItems(e) {
        this.childShowFilterRowRef.current.DataGridContextMenuItemsService(e, this.dataGrid);
    }

    calculateSearchExpression(filtervalue, selectedFilterOperations, target) {
        const query = filtervalue.trim();
        const regExpText = "(".concat(
            query
                .replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&')
                .split(" ")
                .join(")|(")
                .concat(")")
        );

        const regExp = new RegExp(regExpText, 'i');
        const getValue = (col) => {
            return (query.length === 0) ? true : regExp.test(col.LogDatetime) || regExp.test(col.Code) || regExp.test(col.EventType) || regExp.test(col.ColumnName) || regExp.test(col.Description) || regExp.test(col.UserID);
        }
        if (target !== "search") return;
        return [[getValue, '=', true]];
    }

    onCellRender(cellData) {
        const search = this.state.searchText.trim();
        if (search.length === 0) return cellData.value;
        const regExpText = "(".concat(
            search
                .replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&')
                .split(" ").filter(el => el.length > 0)
                .sort((a, b) => b.length - a.length)
                .join(")|(")
                .concat(")")
        );

        const regExp = new RegExp(regExpText, "ig");
        try {
            const matches = (cellData.value.match(regExp)) ? cellData.value.match(regExp).filter((el) => el !== undefined) : [];
            return (
                <div>
                    {cellData.value
                        .split(regExp)
                        .filter((el) => el !== undefined && el != null)
                        .map((el, index) => {
                            if (el === matches[0]) {
                                matches.shift();
                                return (
                                    <span key={index} className="dx-datagrid-search-text">
                                        {el}
                                    </span>
                                );
                            } else return el;
                        })}
                </div>
            );
        }
        catch (error) { }
    }
    //#endregion

    render() {

        return (
            <React.Fragment>
                <div className="audit-log-navbar">
                    <Flash
                        parentCallback={this.closeFlashMessageCallback}
                        message={this.state.emptyMsg}
                        visible={this.state.displayFlashMessageDialog}
                        severity={this.state.status}
                        container={this.state.size} />

                    <ErrorPopUp
                        parentCallback={this.handleSessionPopUpCallBack}
                        visible={this.state.displaySessionPopUp}
                        title={formatMessage('SessionExpired')}
                        subTitle={formatMessage('PleaseLoginAgain')} />

                    <DownloadPopUp
                        parentCallback={this.handleDownloadPopUpCallBack}
                        hideDownloadPopup={this.handleDownloadConfirmationDialog}
                        isDeleteBtnFocus={this.state.isDeleteBtnFocus}
                        visible={this.state.displayDownloadPopUp} />
                </div>

                <Form
                    id={"audit-log-form"}
                    width={"100%"}
                    colCount={8}>
                    <Item colSpan={8}>

                        <div className="package-item-header-title">
                            <ListingHeader6
                                placeHolderText={formatMessage('SearchPlaceholderText')}
                                buttonText={formatMessage('Search')}
                                companyCaption={formatMessage('Company')}
                                dateRangeCaption={formatMessage('DateRange')}
                                userIDCaption={formatMessage('UserID')}
                                docTypeCaption={formatMessage('DocumentType')}
                                docNoCaption={"Document No."}
                                settingCaption={formatMessage('Settings')}
                                codeCaption={formatMessage('Code')}
                                filterText={formatMessage('Filter')}
                                displayDocColumn={false}
                                dataSource_Companies={this.state.companies}
                                dataSource_UserID={this.state.userID}
                                dataSource_DocType={this.state.docType}
                                defaultUsername={this.state.defaultUsername}
                                parentItemCode={this.state.parentItemCode}
                                dataSource_AllSetting={this.state.allSetting}
                                buttonMode={this.state.buttonMode}
                                defaultValue={CompanyID()}
                                defaultName={DefaultName()}
                                codeProp={'CO_Code'}
                                nameProp={'CO_Name'}
                                valueProp={'CO_ID'}
                                displayProp={'IAG_Description'}
                                defaultDateFormat={this.state.defaultDateFormat}
                                onSearchValueChange={this.searchValueChangeHandler}
                                onSelectValueChange={this.selectValueChangeHandler}
                                onFilterClick={this.onFilterClick}
                                onSelectDateCallBack={this.onSelectDateCallBack}
                                tagBoxChanged={this.setTagBoxValue}
                                onTagBoxChanges={this.onSelectUserIDCallBack}
                                onValueChange={this.onValueChange}
                                ref={(ref) => this.childHeader = ref} />
                        </div>
                    </Item>

                    <Item colSpan={8}>
                        <div className="dx-fieldset">
                            <div className="dx-field">
                                <div className="dx-field-value">
                                    <DataGrid
                                        id={DefaultSMIAuditLog.controlID}
                                        allowColumnReordering={true}
                                        allowColumnResizing={true}
                                        columnAutoWidth={true}
                                        columnHidingEnabled={false}
                                        columnResizingMode={"widget"}
                                        dataSource={this.state.auditLogSetting}
                                        defaultFocusedRowIndex={0}
                                        focusedRowEnabled={true}
                                        keyExpr="UniqueId"
                                        onCellPrepared={this.onCellPrepared}
                                        onContentReady={this.onContentReady}
                                        onContextMenuPreparing={this.addMenuItems}
                                        onOptionChanged={this.onOptionChanged}
                                        onEditorPreparing={this.onEditorPreparing}
                                        //onFocusedRowChanged       = {this.onFocusedRowChanged}
                                        ref={this.dataGridRef}
                                        rowAlternationEnabled={true}
                                        showBorders={true}
                                        showRowLines={true}
                                        className='dx-datagrid-items'>

                                        <Export enabled={false} />
                                        <FilterRow visible={this.state.showFilterRow} />
                                        <Grouping contextMenuEnabled={true} />
                                        <LoadPanel indicatorSrc={loadingBar} />
                                        <GroupPanel visible={this.state.showGroupPanel} />
                                        <Paging defaultPageSize={DefaultPager.defaultPageSize_listing} />
                                        <Pager showPageSizeSelector={true}
                                            allowedPageSizes={DefaultPager.allowedPageSizes_listing}
                                            showNavigationButtons={true}
                                            showInfo={this.showInfoChange}
                                            visible={true} />

                                        <ColumnChooser enabled={false}
                                            mode={"select"} />

                                        <SearchPanel
                                            text={this.state.searchText}
                                            visible={false} />

                                        <Scrolling
                                            mode="standard"
                                            useNative={false}
                                            scrollByContent={true}
                                            scrollByThumb={true}
                                            showScrollbar="always" />

                                        <StateStoring
                                            enabled={this.state.allowSaveLayout}
                                            type={"custom"}
                                            customLoad={this.loadState}
                                            customSave={this.saveState}
                                            ignoreColumnOptionNames={DefaultStateStoringIgnore} />

                                        <Column
                                            dataField={'LogDatetime'}
                                            caption={formatMessage('LogDateTime')}
                                            width={200}
                                            cellRender={this.onCellRender} />

                                        <Column
                                            dataField={'Code'}
                                            caption={formatMessage('Code')}
                                            minWidth={60}
                                            cellRender={this.onCellRender} />

                                        <Column
                                            dataField={'EventType'}
                                            caption={formatMessage('EventType')}
                                            minWidth={60}
                                            cellRender={this.onCellRender} />

                                        <Column
                                            dataField={'ColumnName'}
                                            caption={formatMessage('ColumnName')}
                                            minWidth={60}
                                            cellRender={this.onCellRender} />

                                        <Column
                                            dataField={'Description'}
                                            caption={formatMessage('Description')}
                                            minWidth={60}
                                            cellRender={this.onCellRender} />

                                        <Column
                                            dataField={'UserID'}
                                            caption={formatMessage('UserID')}
                                            minWidth={60}
                                            cellRender={this.onCellRender} />

                                        <Summary>
                                            <TotalItem
                                                name="totalCount"
                                                summaryType="count"
                                                displayFormat="Rec: {0}"
                                                showInColumn="LogDatetime" />
                                        </Summary>

                                    </DataGrid>
                                </div>
                            </div>
                        </div>
                    </Item>
                    <ChildShowFilterRow
                        ref={this.childShowFilterRowRef}
                        displayColumnChooser={this.state.allowDisplayColumnChooser}
                        displayRestoreLayout={this.state.allowRestoreLayout}
                        displayExportGrid={this.state.allowExportGrid}
                        getChildFilterRow={this.showFilterRow}
                        getChildGroupPanel={this.showGroupPanel}
                        restoreLayout={this.restoreLayout}
                        showRestoreMsg={this.showMsgHandler} />
                </Form>
            </React.Fragment>
        )
    }
}

export default AuditLog;