import React, { useCallback, useState } from 'react';
import Form from 'devextreme-react/form';
import { Item } from 'devextreme-react/select-box';
import { Button } from 'devextreme-react/button';

//language
import { formatMessage, locale } from 'devextreme/localization';
import ReactTooltip from 'react-tooltip';

//components
import { 
    Singleselectiondropdowntable, 
    CalenderSelectionDropdown, 
    Singleselectiondropdown,
    TextBox2,
    TextBox1
} from '../../../components/index';

//cookies
import { SearchText } from '../../../utils/default-cookies';
import './listing-header-4.scss';

const ListingHeader5 = ( props ) => {

    const status = [
        {
            displayValue: 'Draf'
        },
        {
            displayValue: 'Posted'
        },
        {
            displayValue: 'Cancel'
        }
    ]

    const dateType = [
        {
            displayValue: 'Created Date'
        },
        {
            displayValue: 'Document Date'
        },
        {
            displayValue: 'Posting Date'
        }
    ]

    var selectedCompanyAIG = [];
    const [searchValue, setSearchValue] = useState(SearchText()); 

    const onCalBtnClick = () => {
        props.onCalBtnClick()
    }

    const onDocActionBtnClick = () => {
        props.onDocActionBtnClick()
    }
    
    const onTbvalueChange = useCallback((value) => {
        setSearchValue(value);
        props.onSearchValueChange(value);
    }, [props.onSearchValueChange]);   

    return(
        <>
        <Form  colCount={8}>
            <Item colSpan={2}>
                <TextBox1 
                    displayValue= {'value'}
                    value       = {props.companyAIGvalue}
                    disabled    = {true}
                    caption     = {props.captionTextbox}
                    stylingMode = {'filled'} />
            </Item>
            
            <Item colSpan={6}></Item>

            <Item colSpan={2}>
                <CalenderSelectionDropdown
                    caption     = {'Date'}
                    className   = {'filter-drop-down'}
                    // dataSource  ={props.dataSource} 
                    // defaultValue={props.defaultName}
                    // placeholder ={props.placeholder}
                    // caption     ={props.captionDropdown}
                    // valueProp   ={props.valueProp}
                    // codeProp    ={props.codeProp}
                    // nameProp    ={props.nameProp}
                    // displayProp ={props.displayProp}
                    // onSelectionChanged={onSelectionChanged}
                    />
            </Item>

            <Item colSpan={2}>
                <Singleselectiondropdown
                    caption     = {props.captionDropdown2}
                    dataSource  = {dateType} 
                    valueExpr   = {'displayValue'}
                    displayExpr = {'displayValue'}
                    placeholder = {props.placeHolderText2} />
            </Item>

            <Item colSpan={1}>
                <div className="dx-fieldset">
                    <div className="dx-field">
                        <div className="dx-field-value-2" >
                            <Button 
                                icon        = {"glyphicon icon-filter-icon"}
                                type        = {"default"}
                                stylingMode = {"contained"}
                                text        = {props.buttonText4}
                                className   = {"filter-btn"} />
                        </div> 
                    </div>
                </div>
            </Item>

            <Item colSpan={3}></Item>

            <Item colSpan={8}>
                <hr className="purchase-invoice-line-separator"/>
            </Item>

            <Item colSpan={4}>
                <TextBox2
                    placeHolderText = {props.placeHolderText}
                    buttonStyle     = {'search-word'}
                    buttonText      = {props.buttonText}
                    value           = {searchValue}
                    onTbvalueChange = {onTbvalueChange}
                    visible         = {true}/>
            </Item>

            <Item colSpan={2}/>

            <Item colSpan={2}>
                <div className="dx-fieldset">
                <div className="dx-field">
                <div className="dx-field-value" >
                <div data-tip = {formatMessage("Email")}  data-for="email"  className="containerBtn left">
                        <div className="icon-email-icon"/>
                    <ReactTooltip id="email" place="bottom" effect="solid"  arrowColor="#474751" />
                </div>
                <div data-tip = {formatMessage("PRINT")}  data-for="print"  className="containerBtn middle">
                        <div className="icon-print-icon"/>
                    <ReactTooltip id="print" place="bottom" effect="solid"  arrowColor="#474751" />
                </div>
                <div data-tip = {formatMessage("Whatsapp")}  data-for="whatsapp"  className="containerBtn right">
                        <div className="icon-whatsapp-icon" />
                    <ReactTooltip id="whatsapp" place="bottom" effect="solid"  arrowColor="#474751" />
                </div>
                </div>
                </div>
                </div>  
            </Item>
        </Form>
        </>
    )
}

export default ListingHeader5;