import React from 'react';
import Form from 'devextreme-react/form';
import { Item } from 'devextreme-react/select-box';
import { Button } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import '../pop-up/popup.scss';
import { Popup } from 'devextreme-react/popup';
import ErrorPopUp from '../pop-up/error-popup';
import ScrollView from 'devextreme-react/scroll-view';

//#region customized 
//components
import TextBox2 from '../../components/text-box/text-box-2';
import Flash from '../message/flash'

//layouts
import ListingHeader3 from '../../layouts/listing/listing-header/listing-header-3';

//default 
import { DefaultPager } from '../../utils/default-data-grid-settings';
import { DefaultSMIItemAdvSearch, DefaultSMIPackageItemDetails } from '../../utils/default-smi';
import { CompanyID, UserID, ClientID, Token, DecimalPoints } from '../../utils/default-cookies';

//api
import { GetAdvanceSearchItemByCompanyID, GetAddNewItemURL, GetAdvanceSearchNotIncludePackageItemByCompanyID } from '../../pages/inventory-setting/package-item/package-item-services';
import { GetUserLayout } from '../../api/user-preferred-layout';

//language
import { formatMessage } from 'devextreme/localization';

//DataGridDefault
import { DataGridDefault, customSearch } from '../';
//#endregion

class ItemPopUp extends React.Component {
    constructor(props) {
        super(props);

        this.dataGridRef = React.createRef();
        this.quickSearchRef = React.createRef();

        this.addNewItem = this.addNewItem.bind(this);
        this.clearItemSelection = this.clearItemSelection.bind(this);
        this.searchTagValueChangeHandler = this.searchTagValueChangeHandler.bind(this)
        this.searchValueChangeHandler = this.searchValueChangeHandler.bind(this);
        this.onFlashMessageTimer = this.onFlashMessageTimer.bind(this);
        this.showMsgHandler = this.showMsgHandler.bind(this);
        this.closeFlashMessageCallback = this.closeFlashMessageCallback.bind(this);
        this.getPostMessageObj = this.getPostMessageObj.bind(this);
        this.openSourceTab = this.openSourceTab.bind(this);
        this.popupOnShown = this.popupOnShown.bind(this);
        this.popupOnHidden = this.popupOnHidden.bind(this);
        this.handleSessionPopUpCallBack = this.handleSessionPopUpCallBack.bind(this);
        this.handlePreferedLayout = this.handlePreferedLayout.bind(this);
        this.filterBySearchText = this.filterBySearchText.bind(this);

        this.fetchDataAPI = this.fetchDataAPI.bind(this);

        this.state = {
            searchText: '',
            searchTag: 'IM_Code|IM_Description|IM_Description2',
            packageItemList: [],
            emptyMsg: '',
            addItemURL: '',
            displayFlashMessageDialog: false,
            displaySessionPopUp: false,
            status: 'success',
            size: 'flash-message-success-container',
            preferedLayout: [],
            format: {
                type: 'fixedPoint',
                precision: DecimalPoints(),
            }
        };

        this.searchbox = [
            {
                header: 'IM_Code',
                title: formatMessage('Code')
            },
            {
                header: 'IM_Description',
                title: formatMessage('Description')
            },
            {
                header: 'IM_Description2',
                title: formatMessage('Description2')
            }
        ]

        this.defaultValue = [
            'IM_Code',
            'IM_Description',
            'IM_Description2'
        ]

        this.getSelectedRowKeys = () => {
            return this.dataGrid.getSelectedRowKeys();
        }

        this.getSelectedRowsData = () => {
            return this.dataGrid.getSelectedRowsData();
        }
    }

    get dataGrid() {
        // `current.instance` points to the UI component instance
        return this.dataGridRef.current.instance;
    }

    async searchValueChangeHandler(event) {
        this.setState({ searchText: event });

        if (this.props.pageName === DefaultSMIPackageItemDetails.moduleURL) {
            const dataSource_AdvSearchItem = await GetAdvanceSearchNotIncludePackageItemByCompanyID(Token(), CompanyID(), ClientID(), UserID(), this.state.searchText, this.state.searchTag);
            this.handleAdvSearchItems(dataSource_AdvSearchItem);
        }
        else {
            const dataSource_AdvSearchItem = await GetAdvanceSearchItemByCompanyID(Token(), CompanyID(), ClientID(), UserID(), this.state.searchText, this.state.searchTag);
            this.handleAdvSearchItems(dataSource_AdvSearchItem);
        }

        this.filterBySearchText();
    }

    searchTagValueChangeHandler = (event) => {
        this.setState({ searchTag: event });
    }

    addNewItem() {
        var currentRowsData = this.getSelectedRowsData();

        this.props.parentCallback(currentRowsData);
        this.dataGrid.clearSelection();
    }

    closeFlashMessageCallback = () => {
        this.setState(state => ({
            displayFlashMessageDialog: !state.displayFlashMessageDialog
        }))
    }

    clearItemSelection() {
        this.dataGrid.clearSelection();
        this.props.hidePopup();
    }

    handleAdvSearchItems = (result) => {
        this.setState({ packageItemList: result });
    }

    handleSessionPopUpCallBack = () => {
        this.setState(state => ({
            displaySessionPopUp: !state.displaySessionPopUp
        }))
    }

    handlePreferedLayout = (result) => {
        this.setState({ preferedLayout: result });
    }

    componentDidMount() {
        document.addEventListener('keydown', this.onFlashMessageTimer, false);
        document.addEventListener('mousedown', this.onFlashMessageTimer, false);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onFlashMessageTimer, false);
        document.removeEventListener('mousedown', this.onFlashMessageTimer, false);
    }

    async fetchDataAPI() {
        const [dataSource_AdvSearchItem, addNewItem_URL, UserPreferedLayout] = await Promise.all([
            this.props.pageName === DefaultSMIPackageItemDetails.moduleURL ? GetAdvanceSearchNotIncludePackageItemByCompanyID(Token(), CompanyID(), ClientID(), UserID(), this.state.searchText, this.state.searchTag) : GetAdvanceSearchItemByCompanyID(Token(), CompanyID(), ClientID(), UserID(), this.state.searchText, this.state.searchTag),
            GetAddNewItemURL(Token(), CompanyID()),
            GetUserLayout(Token(), UserID(), DefaultSMIItemAdvSearch.moduleURL, DefaultSMIItemAdvSearch.controlID)
        ])

        if (dataSource_AdvSearchItem === 'Error: 401' || addNewItem_URL === 'Error: 401' || UserPreferedLayout === 'Error: 401') {
            this.handleSessionPopUpCallBack();
        }
        else {
            this.setState(prevState => ({
                addItemURL: addNewItem_URL,
                format: {
                    ...prevState.format,
                    precision: DecimalPoints(),
                }
            }));

            this.handleAdvSearchItems(dataSource_AdvSearchItem);
            this.handlePreferedLayout(UserPreferedLayout);
        }
    }

    onFlashMessageTimer() {
        if (this.state.displayFlashMessageDialog === true) {
            setTimeout(() => this.setState({ displayFlashMessageDialog: false }), 1000)
        }
    }

    showMsgHandler(params, value, size) {
        if (params !== '') {
            this.setState({ emptyMsg: params, displayFlashMessageDialog: true, status: value, size: size });
        }
        else {
            this.setState({ emptyMsg: '', displayFlashMessageDialog: false, status: value, size: size });
        }
    }

    openSourceTab() {
        window.parent.postMessage(this.getPostMessageObj(""), "*");
    }

    getPostMessageObj() {
        return {
            source: window.location.host,
            target: process.env.REACT_APP_API_ALAYA_URL,
            eval: "parent.addTab('Item Creation Details', '" + this.state.addItemURL + "')"
        }
    }

    popupOnShown(e) {
        this.fetchDataAPI();
        this.quickSearchRef.setFocus(e);
    }

    popupOnHidden(e) {
        this.quickSearchRef.clear(e);
        this.dataGrid.pageIndex(0);
        this.dataGrid.clearSelection();
    }

    filterBySearchText() {
        const searchQuery = this.state.searchText.trim();

        if (searchQuery.length === 0) {
            const getValue = () => true;
            this.dataGrid.filter([getValue, '=', true]);
            this.dataGrid.option('navigateToFirst', true);
            return;
        }

        const regExpText = "(".concat(
            searchQuery
                .replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&')
                .split(" ")
                .join(")|(")
                .concat(")")
        );

        const regExp = new RegExp(regExpText, 'i');

        let columnConfig = [];

        this.dataGrid.getVisibleColumns().forEach(function (column) {
            if (column.hasCustomSearch) {
                const dataField = column.dataField;
                const format = column?.format;
                columnConfig.push({ dataField, format });
            }
        });

        const getValue = (column) => {
            return columnConfig.some(function (config) {
                let value = column[config.dataField];

                if (config.format) {
                    // to search columns with different format text
                    const base = 10 ** config.format.precision;
                    value = (Math.round(parseFloat((value * base).toFixed(10))) / base).toFixed(config.format.precision);
                }

                return regExp.test(value);
            });
        }

        this.dataGrid.filter([getValue, '=', true]);
        this.dataGrid.option('navigateToFirst', true);
    }

    render() {
        return (
            <Popup
                className="item-popup"
                showTitle={true}
                title={formatMessage("AddPackageSubItem")}
                onHiding={this.props.hidePopup}
                onShown={this.popupOnShown}
                onHidden={this.popupOnHidden}
                visible={this.props.visible}
                dragEnabled={true}
                closeOnOutsideClick={true}
                width={'81%'}
                height={'95%'}
            >
                <ErrorPopUp
                    parentCallback={this.handleSessionPopUpCallBack}
                    visible={this.state.displaySessionPopUp}
                    title={formatMessage('SessionExpired')}
                    subTitle={formatMessage('PleaseLoginAgain')}
                />
                <Flash
                    parentCallback={this.closeFlashMessageCallback}
                    message={this.state.emptyMsg}
                    visible={this.state.displayFlashMessageDialog}
                    severity={this.state.status}
                    container={this.state.size}
                />
                <ScrollView width='100%' height='90%'>
                    <Form
                        id="package-item-form"
                        width="100%"
                        colCount={8}
                    >
                        <Item colSpan={4}>
                            <ListingHeader3
                                parentCallback={this.searchTagValueChangeHandler}
                                textField={'dx-fieldset-popup-text'}
                                inputField={'dx-fieldset-popup'}
                                dataSource={this.searchbox}
                                valueExpr={'header'}
                                displayExpr={'title'}
                                caption={formatMessage('SearchColumn')}
                                defaultValue={this.defaultValue} />
                        </Item>
                        <Item colSpan={4}></Item>
                        <Item colSpan={4}>
                            <TextBox2
                                placeHolderText={formatMessage('QuickSearchPlaceholderText')}
                                buttonStyle={'search-word'}
                                focusStateEnabled={true}
                                textField={'popup-text'}
                                buttonText={formatMessage('Search')}
                                value={this.state.searchText}
                                onTbvalueChange={this.searchValueChangeHandler}
                                ref={(ref) => this.quickSearchRef = ref}
                                visible={true}>
                            </TextBox2>
                        </Item>
                        <Item colSpan={4}>
                            <Button
                                className="add-item-btn"
                                hoverStateEnabled={false}
                                activeStateEnabled={false}
                                focusStateEnabled={false}
                                onClick={this.openSourceTab}>
                                <span className="icon-add-icon" />
                                <span className="icon-add-text">{formatMessage('AddNew')}</span>
                            </Button>
                        </Item>
                    </Form>
                    <div className="module-data-grid-popup">
                        <DataGridDefault
                            allowDisplayColumnChooser={this.props.allowDisplayColumnChooser}
                            allowExportGrid={this.props.allowExportGrid}
                            allowedPageSizes={DefaultPager.allowedPageSizes_details}
                            allowRestoreLayout={this.props.allowRestoreLayout}
                            allowSaveGridLayout={this.props.allowSaveGridLayout}
                            className='dx-datagrid-items'
                            dataGridRef={this.dataGridRef}
                            dataSource={this.state.packageItemList}
                            defaultPageSize={DefaultPager.defaultPageSize_details}
                            defaultSMI={DefaultSMIItemAdvSearch}
                            keyExpr="IM_ID"
                            noDataText={formatMessage("dxDataGrid-noDataText")}
                            preferedLayout={this.state.preferedLayout}
                            showMsgHandler={this.showMsgHandler}
                            customSelectionBox={true}
                        >
                            <Column
                                allowHiding={false}
                                showInColumnChooser={false}
                                dataField={'IM_ID'}
                                width={90}
                                visible={false}
                            />
                            <Column
                                caption={formatMessage('ItemCode')}
                                dataField={'IM_Code'}
                                cellRender={customSearch({ allowSearch: true, searchText: this.state.searchText })}
                                hasCustomSearch={true}
                                minWidth={60}
                            />
                            <Column
                                caption={formatMessage('Description')}
                                dataField={'IM_Description'}
                                cellRender={customSearch({ allowSearch: true, searchText: this.state.searchText })}
                                hasCustomSearch={true}
                                minWidth={60}
                            />
                            <Column
                                caption={formatMessage('Description2')}
                                dataField={'IM_Description2'}
                                cellRender={customSearch({ allowSearch: true, searchText: this.state.searchText })}
                                hasCustomSearch={true}
                                minWidth={60}
                            />
                            <Column
                                caption={formatMessage('Price1')}
                                dataField={'IU_SalesPrice1'}
                                minWidth={60}
                                format={this.state.format}
                            />
                            <Column
                                caption={formatMessage('Price2')}
                                dataField={'IU_SalesPrice2'}
                                minWidth={60}
                                format={this.state.format}
                            />
                            <Column
                                caption={formatMessage('Price3')}
                                dataField={'IU_SalesPrice3'}
                                minWidth={60}
                                format={this.state.format}
                            />
                            <Column
                                caption={formatMessage('Price4')}
                                dataField={'IU_SalesPrice4'}
                                minWidth={60}
                                format={this.state.format}
                            />
                            <Column
                                caption={formatMessage('Price5')}
                                dataField={'IU_SalesPrice5'}
                                minWidth={60}
                                format={this.state.format}
                            />
                            <Column
                                caption={formatMessage('Price6')}
                                dataField={'IU_SalesPrice6'}
                                minWidth={60}
                                format={this.state.format}
                            />
                            <Column
                                caption={formatMessage('PurchasePrice')}
                                dataField={'IU_PurchasePrice'}
                                minWidth={60}
                                format={this.state.format}
                            />
                            <Column
                                caption={formatMessage('ItemGroup')}
                                dataField={'IG_Description'}
                                minWidth={60}
                            />
                            <Column
                                caption={formatMessage('ItemCategory')}
                                dataField={'IC_Description'}
                                minWidth={60}
                            />
                            <Column
                                caption={formatMessage('QtyAvailable')}
                                dataField={'IW_QtyAvailable'}
                                minWidth={60}
                            />
                        </DataGridDefault>
                    </div>
                </ScrollView>
                <div className="item-popup-footer">
                    <div className="left-btn-container">
                        <Button
                            hoverStateEnabled={true}
                            activeStateEnabled={false}
                            focusStateEnabled={false}
                            className="cancel-btn"
                            text={formatMessage("Cancel")}
                            type="normal"
                            stylingMode="contained"
                            onClick={this.clearItemSelection}>
                        </Button>
                    </div>
                    <div className="right-btn-container">
                        <Button
                            hoverStateEnabled={true}
                            activeStateEnabled={false}
                            focusStateEnabled={false}
                            className="apply-btn"
                            text={formatMessage("OK")}
                            onClick={this.addNewItem}
                            type="default"
                            stylingMode="contained">
                        </Button>
                    </div>
                </div>
            </Popup>
        );
    }
}

export default ItemPopUp;