import React, { forwardRef, useCallback, useState, useImperativeHandle, useRef} from 'react';
import Form from 'devextreme-react/form';
import { Item } from 'devextreme-react/select-box';
import { Button } from 'devextreme-react/button';
import TextBox1 from '../../../components/text-box/text-box-1';
import TextBox2 from '../../../components/text-box/text-box-2';

//components
import { 
    Singleselectiondropdowntable, 
    CalenderSelectionDropdown, 
    Singleselectiondropdown,
    ListingHeaderTagBox
} from '../../../components/index';

//cookies
import { CompanyID, SearchText } from '../../../utils/default-cookies';
import ListingHeader3 from './listing-header-3';
import './listing-header-4.scss';

const ListingHeader6 = 
    forwardRef((props, ref) => {    
    
    var searchBox = React.createRef(); 

    var selectedCompanyAIG = [];
    const [companyAIGvalue, setCompanyAIGValue] = useState("");
    const [searchValue, setSearchValue] = useState(SearchText()); 
    const [tagValue, setTagValue] = useState(props.defaultUsername); 

    console.log(props.parentItemCode)
    useImperativeHandle(ref, () => ({
        clearText(){
            searchBox.clear();
        },

        setFocus(){
            searchBox.setFocus();
        }
            
    }));

    if(props.dataSource_Companies.length > 0) {
        //Cannot set triple equation
        selectedCompanyAIG =  props.dataSource_Companies.filter(function(data) {
            return data[props.valueProp] == CompanyID();
        });

        if(selectedCompanyAIG.length > 0 && companyAIGvalue === "") {
            setCompanyAIGValue(selectedCompanyAIG[0][props.nameProp]);
        }
    }

    const onSelectionChanged = useCallback((selection,displayProp,valueProp) => {
        setCompanyAIGValue(selection[displayProp]);
        props.onSelectValueChange(selection[valueProp]);
    }, [props.onSelectValueChange]);

    const onTagChanged = (value) => {
      //code
    }
    
    const onSelectDate = (fromDate, toDate) => {
        props.onSelectDateCallBack(fromDate, toDate);
    }

    const onSelectUserID = (value) => {
        var selectedUser = value;

        var userIDList = [];
        for(var i=0; i<props.dataSource_UserID.length; i++){
            
            for(var j=0; j<selectedUser.length; j++){
                if(props.dataSource_UserID[i].US_UserID == selectedUser[j]){
                    userIDList.push(props.dataSource_UserID[i].US_ID);
                }
            }
        }

        setTagValue(value)
        props.onTagBoxChanges(userIDList)
        props.tagBoxChanged(value)
    }

    const onFilterClick = useCallback(() => {
        props.onFilterClick();
    }, [props.onFilterClick]);
    
    const onValueChange = useCallback((value) => {
        console.log(value);
        props.onValueChange(value);
    }, [props.onValueChange]);

    const onTbvalueChange = useCallback((value) => {
        setSearchValue(value);
        props.onSearchValueChange(value);
    }, [props.onSearchValueChange]);   

    return(
        <>
        <Form  colCount={8}>

            {/* First line */}
            <Item colSpan={2}>
                <Singleselectiondropdowntable 
                    dataSource          = {props.dataSource_Companies} 
                    defaultValue        = {props.defaultValue}
                    defaultName         = {props.defaultName}
                    placeholder         = {props.placeholder}
                    caption             = {props.companyCaption}
                    valueProp           = {props.valueProp}
                    codeProp            = {props.codeProp}
                    nameProp            = {props.nameProp}
                    displayProp         = {props.displayProp}
                    onSelectionChanged  = {onSelectionChanged} 
                />
            </Item>
            
            <Item colSpan={2}>
                <CalenderSelectionDropdown
                    caption        = {props.dateRangeCaption}
                    id             = {"calendar-dropdown-box"}
                    className      = {'filter-drop-down'}
                    parentCallback = {onSelectDate}
                    dateFormat     = {props.defaultDateFormat} /> 
            </Item>

            <Item colSpan={2}>
                <ListingHeaderTagBox
                    caption         = {props.userIDCaption}
                    dataSource      = {props.dataSource_UserID}
                    defaultValue    = {props.defaultUsername}
                    valueExpr       = {'US_UserID'}
                    displayExpr     = {'US_UserID'}
                    parentCallback  = {onSelectUserID} />    
            </Item>

            <Item colSpan={2}></Item>

            {/* Second line */}
            <Item colSpan={2} visible={props.displayDocColumn}>
                <ListingHeader3
                    caption         = {props.docTypeCaption}
                    dataSource      = {props.dataSource_DocType}
                    textField       = {'dx-fieldset-text'}
                    inputField      = {'dx-fieldset'}
                    valueExpr       = {'DT_Description'}
                    displayExpr     = {'DT_Description'}
                    parentCallback  = {onTagChanged} />    
            </Item>

            <Item colSpan={2} visible={props.displayDocColumn}>
                <TextBox1 
                    displayValue= {'value'}
                    caption     ={'Document No.'}
                    stylingMode ={'outlined'}
                    placeholder ={'Input Document No.'}>
                </TextBox1>
            </Item>

            <Item colSpan={4} visible={props.displayDocColumn}></Item>

            {/* Third line */}
            <Item colSpan={2}>
                <Singleselectiondropdown
                    dataSource      = {['Package Item']}
                    defaultValue    = {'Package Item'}
                    caption         = {props.settingCaption} 
                    parentCallback  = {onTagChanged} />
            </Item>

            <Item colSpan={2}>
                <TextBox1 
                    displayValue    = {props.parentItemCode}
                    caption         = {props.codeCaption}
                    value           = {props.parentItemCode}
                    displayStar     = {true}
                    onTbvalueChange = {onValueChange}
                    stylingMode     = {'outlined'}
                    placeholder     = {'Input Code'}>
                </TextBox1>
            </Item>

            <Item colSpan={1}>
                <div className="dx-fieldset">
                    <div className="dx-field">
                        <div className="dx-field-value-2" >
                            <Button 
                                icon        = {"glyphicon icon-filter-icon"}
                                type        = "default"
                                stylingMode = {"contained"}
                                text        = {props.filterText}
                                className   = {"filter-btn"}
                                onClick     = {onFilterClick} />
                        </div> 
                    </div>
                </div>
            </Item>

            <Item colSpan={3}></Item>

            <Item colSpan={8}>
                <hr className="purchase-invoice-line-separator"/>
            </Item>

            <Item colSpan={4}>
                <TextBox2
                    placeHolderText     = {props.placeHolderText}
                    buttonStyle         = {'search-word'}
                    buttonText          = {props.buttonText}
                    value               = {searchValue}
                    onTbvalueChange     = {onTbvalueChange}
                    visible             = {true}
                    ref={(ref) => searchBox=ref}/>
            </Item>
         
        </Form>
        </>
    )
});

export default ListingHeader6;