import React from 'react';
import './access-denied.scss';

//language
import { formatMessage } from 'devextreme/localization';

class AccessDenied extends React.Component {
  constructor(props) {
    super(props);
  }
  
   
  render() {

    return (
      <>
        {this.props.visible == false ?
        <div className="access-denied-wrapper">
          <i className="dx-icon glyphicon icon-attention-icon-01"/>
          
          <div className="access-denied-header">{formatMessage("AccessDeniedHeader")}</div>
          <div className="access-denied-description">{formatMessage("AccessDeniedDescription")}</div>
        </div>
        : "" }
      </>
    )
  }
}

export default AccessDenied;