import './components/web-font/fontello/scss/fontello.scss'
import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.scss';
import './themes/generated/theme.additional.scss';
import './themes/customized/dx-styles.scss';
import './themes/customized/override.base.scss';
import './themes/customized/main.scss';
import './themes/customized/icon.scss';
import './themes/customized/font.scss';

import React from 'react';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';
import { HashRouter as Router } from 'react-router-dom';

//language
import { locale, loadMessages } from 'devextreme/localization';
import enMessages from './lang/en.json';
import msMessages from './lang/ms.json';
import zhCHSMessages from './lang/zhCHS.json';
import zhCHTMessages from './lang/zhCHT.json';
import { useDevConfig } from './dev/useDevConfig';

function App() {
  const { user, loading } = useAuth();

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (user) {
    return <Content />;
  }

  return <UnauthenticatedContent />;
}

export default function () {
  const screenSizeClass = useScreenSizeClass();

  // Dev Config
  // useDevConfig();

  //language
  initMessages();
  locale(getLocale());
  function getLocale() {
    const locale = sessionStorage.getItem("locale");
    return locale != null ? locale : "en";
  }
  //function setLocale(locale) {
  //  sessionStorage.setItem("locale", locale);
  //}
  function initMessages() {
    loadMessages(enMessages);
    loadMessages(msMessages);
    loadMessages(zhCHSMessages);
    loadMessages(zhCHTMessages);
  }

  return (
    <Router>
      <AuthProvider>
        <NavigationProvider>
          <div className={`app ${screenSizeClass}`}>
            <App />
          </div>
        </NavigationProvider>
      </AuthProvider>
    </Router>
  );
}
