import React from "react";
import TagBox from 'devextreme-react/tag-box';
import "./listing-header.scss"

const ListingHeader3 = (props) => {

    const _onValueChanged = (value) => {
        props.parentCallback(value)
    }

    const _onSelectionChanged = (e) => {
        //get value in array and turn into string 
        const value = e.component.option("value");
    }

    return (
        <>
            {/* dx-fieldset-popup-text || dx-fieldset-text */}
            <div className= {props.textField}> 
                <div className="dx-field">
                    <div className="dx-field-label">{props.caption}</div>
                </div>
            </div>
            {/* dx-fieldset-popup || dx-fieldset  */}
            <div className= {props.inputField}>
                <div className="dx-field">
                    <div className="dx-field-value">
                        <TagBox
                          valueExpr              ={props.valueExpr}
                          dataSource             ={props.dataSource}
                          displayExpr            ={props.displayExpr}
                          defaultValue           ={props.defaultValue}
                          placeholder            ="Select a value"
                          className              ="tagBox filter-drop-down"
                          stylingMode            ={'outlined'}
                          showSelectionControls  ={true}
                          showMultiTagOnly       ={true}
                          showDropDownButton     ={true}
                          searchEnabled          ={true}
                          onValueChange          ={_onValueChanged}
                          maxDisplayedTags       ={3}>
                        </TagBox>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListingHeader3;
