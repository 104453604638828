// to store the SMI_URL in table SystemModuleItem

export const DefaultSMIPackageItem = {
  moduleURL: "r_PackageItem.aspx",
  controlID: "r_gvPackageItem"
}

export const DefaultSMIPackageItemDetails = {
  moduleURL: "r_PackageItemDetail.aspx",
  controlID: "r_gvPackageItemDetails"
}

export const DefaultSMIAuditLog = {
  auditLog: "r_AuditLog.aspx",
  controlID: "r_gvAuditLog"
}

export const DefaultSMIItemAdvSearch =  {
  moduleURL: "r_PackageItemDetail.aspx",
  controlID: "r_gvItemSearchControl"
} 

export const DefaultSMIPurchaseInvoice =  {
  purchaseInvoice: "purchase-invoice",
  controlID: "r_gvPurchaseInvoices"
} 

export const DefaultSMIItemAdvSearchPI =  {
  purchaseInvoiceDetail: "purchase-invoice-details",
  controlID: "r_gvItemSearchControl"
} 