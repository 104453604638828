import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';
import Form from 'devextreme-react/form';
import { Popup } from 'devextreme-react/popup';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { DocumentInfo } from '../../pages/inventory-setting/package-item/package-item-service-mock.js';
import ReactTooltip from 'react-tooltip';
import { Button } from 'devextreme-react/button';
import DropDownButton from 'devextreme-react/drop-down-button';
import { DefaultName } from '../../utils/default-cookies';
import './toolbar.scss';

//#region customized 

//language
import { formatMessage } from 'devextreme/localization';

//#endregion

const Toolbar1 = forwardRef((props, ref) => {

  const [state, setState] = useState({
    currentDocumentInfo: {},
    docInfoPopUpVisible: false,
  });

  const [moreOptionsDropdown, setMoreOptionsDropdownOpen] = useState(false);
  
  const outsideClick = useRef(null);
  useOutsideAlerter(outsideClick);

  useImperativeHandle(
    ref,
    () => ({
        onBackBtnClick() {
          backBtnClick();
        },
        onSaveBtnClick(){
          saveBtnClick();
        },
     }),
  )

  function useOutsideAlerter(ref) {
    useEffect(() => {
    
      function handleOutsideClick(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setMoreOptionsDropdownOpen(false);
        }
      }
  
      document.addEventListener("click", handleOutsideClick);
    
    }, [ref]);
  }  

  const saveBtnClick = async() => {
    props.saveLayout();
  }

  const backBtnClick = () => {
    props.goBack();
  }

  const copyBtnClick = () => {
  }

  const addNewBtnClick = () => {
    //notify('add new button has been clicked!');
  }

  const clickDocumentInfo = () => {
    setMoreOptionsDropdownOpen(prevState => !prevState);
    showInfo(DocumentInfo);
  }

  const clickAuditLog = () => {
    setMoreOptionsDropdownOpen(prevState => !prevState);
    props.openAuditLog();
  }

  const hideInfo = () => {
    setState({
      currentDocumentInfo: {},
      docInfoPopUpVisible: false
    });
  }

  const toggle = () => setMoreOptionsDropdownOpen(prevState => !prevState);

  function showInfo(documentInfo){
      setState({
        currentDocumentInfo:documentInfo,
        docInfoPopUpVisible:true
    });
  }

  return (
    <React.Fragment>
      <Popup
        className="item-popup"
        visible={state.docInfoPopUpVisible}
        onHiding={hideInfo}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showTitle={true}
        title= {formatMessage('DocumentInfo')}
        width={"auto"}
        height={"auto"}>
          
        <Form  colCount={2}>
        <Item colSpan={1}>
            <div className="header-container">
              <div className="doc-info-header">
                <label>{formatMessage('Company')}</label>
              </div>
              <br/>
            <div className="doc-info-title">
              {DefaultName()}
            </div>
          </div>
          </Item>
          <Item colSpan={1}>
          <div className="header-container">
            <div className="doc-info-header">
              <label>{formatMessage('AssociatedCompany')}</label>
            </div>
            <br/>
            <div className="doc-info-title">
              {props.displayIAGDescription}
            </div>
          </div>
          </Item>
          <Item colSpan={1}>
          <div className="header-container">
            <div className="doc-info-header">
              <label>{formatMessage('CreatedBy')}</label>
            </div>
            <br/>
            <div className="doc-info-title">
              {props.displayCreatedBy}
            </div>
          </div>
          </Item>
          <Item colSpan={1}>
          <div className="header-container">
            <div className="doc-info-header">
              {formatMessage('CreatedDateandTime')}
            </div>
              <br/>
            <div className="doc-info-title">
              {props.displayCreatedDate}
            </div>
          </div>
          </Item>
        </Form>
      </Popup>
        <Form colCount={16}>  
            
            <Item colSpan={16}>
                <Toolbar className="toolbar-cus">
                  <Item
                    visible={props.displayBackBtn} 
                    location="before">
                    <div data-tip={formatMessage("BackAltB")} data-for="backBtn" className="backToolbarBtn">
                      
                      <Button
                        id="backBtn"
                        text={formatMessage("Back")}
                        stylingMode="text"
                        onClick={backBtnClick}
                        type="normal"
                        icon="back"
                        height={22}>
                      </Button>

                      <ReactTooltip id="backBtn" place="bottom" effect="solid" arrowColor="#474751" />
                    </div>
                  </Item>
                
                  <Item
                    visible={props.displaySaveBtn}  
                    location="before">
                    <div data-tip={formatMessage("SaveAltS")} data-for="saveBtn" className="toolbarBtn">
                      
                      <Button
                        id="saveBtn" 
                        text={formatMessage("Save")}
                        stylingMode="text"
                        onClick={saveBtnClick}
                        type="normal"
                        icon="save"
                        height={22}>
                      </Button>

                      <ReactTooltip id="saveBtn" place="bottom" effect="solid"  arrowColor="#474751" />
                    </div>
                  </Item>

                  <Item
                    visible={props.displayCopyBtn} 
                    location="before">
                    <div data-tip="Copy" data-for="copyBtn" className="toolbarBtn">
          
                      <Button
                        id="copyBtn" 
                        text={formatMessage("Copy")}
                        stylingMode="text"
                        onClick={copyBtnClick}
                        type="normal"
                        icon="copy"
                        height={22}>
                      </Button>

                      <ReactTooltip id="copyBtn" place="bottom" effect="solid"  arrowColor="#474751"/>
                    </div>
                  </Item>

                  <Item   
                    visible={props.displayAddNewBtn}
                    location="before">
                    <div data-tip="New" data-for="newBtn" className="toolbarBtn">
          
                      <Button
                        id="newBtn" 
                        text={formatMessage("New1")}
                        stylingMode="text"
                        onClick={addNewBtnClick}
                        type="normal"
                        icon="doc"
                        height={22}>
                      </Button>

                      <ReactTooltip id="newBtn" place="bottom" effect="solid"  arrowColor="#474751"/>
                    </div>
                  </Item>

                  <Item
                    visible={props.displayPreviewReport} 
                    location="before">
                    <div data-tip="Preview report" data-for="moreOption" className="dropDownToolbarBtn" id="dropDownBtn">
                      
                      <DropDownButton
                        className="prevDropDown"
                        id="previewBtn" 
                        keyExpr="id"
                        text={formatMessage("PreviewReport")}
                        stylingMode="text"
                        icon="pdffile"
                        displayExpr= 'name'
                        useSelectMode={false}
                        //items= {alignments}
                        selectedItemKey= {3}
                        type="normal"
                        width={115}
                        height={23}>
                      </DropDownButton>
                      
                      <ReactTooltip id="moreOption" place="bottom" effect="solid"  arrowColor="#474751"/>
                    </div>
                  </Item>
                    
                  <Item
                    visible={props.displayMoreOptions} 
                    location="before">

                    <div ref={outsideClick}>
                      <button data-tip={formatMessage("MoreOptions")} data-for="moreOptions" onClick={toggle} className="dropDownToolbarBtn moreOptionsBtn" id="dropDownBtn"> 
                        <div className="more-options-border">
                          <i className="icon-menu-dots"/>
                          <i className="more-options-btn">{formatMessage("MoreOptions")}</i>
                          <i className="icon-down-icon"/>
                          <ReactTooltip id="moreOptions" place="right" effect="solid"  arrowColor="#474751"/>
                        </div>
                      </button>                            
                      
                      {moreOptionsDropdown ?
                          
                          <div className="drop-down-list" >

                            {props.displayAuditLogBtn ? 
                            <button data-tip={formatMessage("AuditLog")} onClick={clickAuditLog}  data-for="document-info-tooltip" className="audit-log-style">
                              <i className="icon-audit-log-new-icon"/>
                              <i className="document-info-btn">{formatMessage("AuditLog")}</i>
                              <ReactTooltip id="document-info-tooltip"></ReactTooltip>
                            </button> : "" }
                            
                            <button data-tip={formatMessage("DocumentInfo")} onClick={clickDocumentInfo}  data-for="document-info-tooltip" className="document-info-style">
                              <i className="icon-document-info-new"/>
                              <i className="document-info-btn">{formatMessage("DocumentInfo")}</i>
                              <ReactTooltip id="document-info-tooltip"></ReactTooltip>
                            </button>
                        </div>
                        : null
                      }
                    </div>
                  </Item>
                </Toolbar>
            </Item>
        </Form>
    </React.Fragment>
  );
})
   
export default Toolbar1;
