import jwt_decode from "jwt-decode";
import moment from "moment";

var access_Token = "";
var user_ID = "";
var company_ID = 0;
var client_ID = "";
var user_Name = "";
var admin_YN = "";
var administrator_YN = "";
var decimal_Points = "";
var default_Name = "";
var language = "";
var search_Text = "";
var license_Expired = false;

export const SetCookies = (cookies, accessToken, userID, companyID, clientID, username, adminYN, administratorYN, language) => {
    cookies.set('userID', userID, { path: '/' }); 
    cookies.set('companyID', companyID, { path: '/' }); 
    cookies.set('clientID', clientID, { path: '/' }); 
    cookies.set('username', username, { path: '/' }); 
    cookies.set('accessToken', accessToken, { path: '/' }); 
    cookies.set('adminYN', adminYN, { path: '/' }); 
    cookies.set('administratorYN', administratorYN, { path: '/' }); 
    cookies.set('language', language, { path: '/'});

    access_Token = cookies.get('accessToken');
    user_ID = cookies.get('userID');
    company_ID = cookies.get('companyID');
    client_ID = cookies.get('clientID');
    user_Name = cookies.get('username');
    admin_YN = cookies.get('adminYN');
    administrator_YN = cookies.get('administratorYN');
    language = cookies.get('language');

    SetLicenseExpired(cookies);
    
}

export const SetDecimalPoints = (cookies, decimalPoints) => {
    cookies.set('decimalPoints', decimalPoints, { path: '/' });
    decimal_Points = cookies.get('decimalPoints');
}

export const SetDefaultName = (cookies, defaultName) => {
    cookies.set('defaultName', defaultName, { path: '/' });
    default_Name = cookies.get('defaultName');
}

export const SetCompanyID = (cookies, companyID) => {
    cookies.set('companyID', companyID, { path: '/' }); 
    company_ID = cookies.get('companyID');
}

export const SetSearchText = (cookies, searchText) => {
    cookies.set('searchText', searchText, { path: '/' });
    search_Text = cookies.get('searchText');
}

export const SetLicenseExpired = (cookies) => {
    //decode jwt 
    var decoded = jwt_decode( cookies.get('accessToken'));
    console.log(decoded.licenseDateExpired);

    const dateIsBefore = moment(moment(decoded.licenseDateExpired, 'DD/MM/YYYY').toISOString()).isBefore(moment());

    cookies.set('licenseExpired', dateIsBefore, { path: '/' });
    license_Expired = cookies.get('licenseExpired');
}

export const DefaultName = () => {
    return default_Name;
}

export const DecimalPoints = () =>{
    return decimal_Points;
}

export const Token = () =>{
    return access_Token;
};

export const UserID = () =>{
    return user_ID;
};

export const CompanyID = () =>{
    return parseInt(company_ID);
};

export const ClientID = () =>{
    return client_ID;
};

export const Username = () =>{
    return user_Name;
};

export const AdminYN = () =>{
    return admin_YN;
};

export const AdministratorYN = () =>{
    return administrator_YN;
};

export const Language = () =>{
    return language;
};

export const SearchText = () => {
    return search_Text;
};

export const LicenseExpired = () => {
    return license_Expired;
};