import React from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid as exportDataGridToXLSX} from 'devextreme/excel_exporter';
import './data-grid-context-menu.scss';

//import font .ttf
import Arial from '../web-font/arial/arial-unicode-ms.ttf';
import { formatMessage } from 'devextreme/localization';

const { forwardRef, useImperativeHandle } = React;

// We need to wrap component in `forwardRef` in order to gain
// access to the ref object that is assigned using the `ref` prop.
// This ref is passed as the second parameter to the function component.
const ChildShowFilterRow = forwardRef((props, ref) => {

  const sendGroupPanelToParent = () => {
    //here calling Parents changeValue
      props.getChildGroupPanel(true);
  }

  const sendFilterRowToParent = () => {
      //here calling Parents changeValue
      props.getChildFilterRow(true);
  }

  const sendRestoreLayoutToParent = () => {
    //here calling parents restore layout function
    props.restoreLayout();
    props.showRestoreMsg(formatMessage('RestoredSuccessfully'), 'success', 'flash-message-success-container');
  }

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({

    DataGridContextMenuItemsService (e, DataGridInstance) {

      const pdfdocumentView = () => {
        //get based on custom columns 
        const columns = DataGridInstance._views.headerPanel._columnsController._visibleColumns

        columns.map( x => {
          //more than 7 above - landscape
          if(x.length >= 7){
            exportGridPdf('l');
          } 
          //less than 6 column - portrait
          else if (x.length <= 6){
            exportGridPdf('p');
          }
        })
      }

      const exportGridPdf = (pdfMode) => {
        var today = new Date();
        const doc = new jsPDF(pdfMode);
        const dataGrid = DataGridInstance;

        doc.addFont(Arial, 'arial-unicode-ms', 'normal');

        //console.log(doc.getFontList());
        exportDataGridToPdf({
          jsPDFDocument: doc,
          component: dataGrid,
          autoTableOptions: {
            styles: {
              font: 'arial-unicode-ms'
            }
          }
        }).then(() => {
          doc.save('Package-Item-Details-' + today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate() + '.pdf');
        });
      }

      const exportGridCSV = () => {
        var today = new Date();
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        const dataGrid = DataGridInstance;
        const header = 'Package-Item';
        exportDataGridToXLSX({
            component: dataGrid,
            worksheet: worksheet,
            customizeCell: function(options) {
                const excelCell = options;
                excelCell.font = { name: 'Arial', size: 12 };
                excelCell.alignment = { horizontal: 'left' };
            } 
        }).then(function() {
            workbook.csv.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob(["\uFEFF" + buffer], { type: 'application/octet-stream' }), header +'-' + today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate() + '.csv')
                });
        });
      }

      const exportGridXLSX = () => {
        var today = new Date();
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        const dataGrid = DataGridInstance;
        const header = 'Package-Item';
        exportDataGridToXLSX({
            component: dataGrid,
            worksheet: worksheet,
            customizeCell: function(options) {
                const excelCell = options;
                excelCell.font = { name: 'Arial', size: 12 };
                excelCell.alignment = { horizontal: 'left' };
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), header +'-' + today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate() + '.xlsx');
                });
        });
      }

      if (e.target === 'header'  || e.target === 'headerPanel') {
        // e.items can be undefined
        if (!e.items) e.items = [];
      
        var groupByThisColumnItem = e.items && e.items.find(i => i.value === "group")
        if (groupByThisColumnItem) {
          groupByThisColumnItem.icon = 'glyphicon icon-group-column-icon';
        }
      
        var ungroupAll = e.items && e.items.find(i => i.value === "ungroupAll")
        if (ungroupAll) {
          ungroupAll.icon = 'minus';
          var groupCount = e.component.columnOption("groupIndex:0");  
          if(!groupCount)  
          {
            e.items.forEach((item)=>{  
              if(item.value === "ungroupAll")  
                  item.disabled = true;  
            })  
          }
        }

        // Add a custom menu item
        e.items.push(
          {
            text: formatMessage('dxDataGrid-groupPanel'),
            icon: 'glyphicon icon-group-panel-icon',
            onItemClick: () => {
              sendGroupPanelToParent();
            }
          },
          {
            text: formatMessage('dxDataGrid-columnChooserTitle'),
            icon: 'glyphicon icon-column-chooser-icon',
            onItemClick: () => {
                console.log(e.column.caption);
                DataGridInstance.showColumnChooser();
            },
          },
          //{
          //  text: 'Save Layout',
          //  icon: 'glyphicon icon-save-layout-icon',
          //  beginGroup: 'true',
          //  onItemClick: () => {
          //      console.log(e.column.caption);
          //  },
          //},
          {
            text: formatMessage('RestoreDefaultLayout'),
            icon: 'glyphicon icon-restore-default-icon',
            beginGroup: 'true',
            onItemClick: () => {
              sendRestoreLayoutToParent();
            },
          },
          {
            text: formatMessage('dxDataGrid-applyFilterText'),
            icon: 'glyphicon icon-filter-row-icon',
            beginGroup: 'true',
            onItemClick: () => {
                sendFilterRowToParent();
            },
          },
          {
            text: formatMessage('dxDataGrid-exportTo'),
            icon: 'glyphicon icon-export-icon',
            onItemClick: () => {
                console.log(e.column.caption);
            },
            items: [
            {
              text: formatMessage('dxDataGrid-exportToPdf'),
              icon: 'glyphicon icon-pdf-red-icon',
              onItemClick: function(args) {
                  console.log(e.column.caption);
                  pdfdocumentView();
              }
            },
            {
              text: formatMessage('dxDataGrid-exportToExcel'),
              icon: 'glyphicon icon-xlsx-green-icon',
              onItemClick: function(args) {
                  console.log(e.column.caption);
                  exportGridXLSX();
              
              }
            },
            {
              text: formatMessage('dxDataGrid-exportToCsv'),
              icon: 'glyphicon icon-csv-greenblue-icon',
              onItemClick: function(args) {
                  console.log(e.column.caption);
                  exportGridCSV();
              }
            }]
          }
        );

        if(props.displayRestoreLayout == false){
          e.items = e.items.filter(x => x.text !== formatMessage('RestoreDefaultLayout'))
        }

        if(props.displayColumnChooser == false){
          e.items = e.items.filter(x => x.text !== formatMessage('dxDataGrid-columnChooserTitle'))
        }

        if(props.displayExportGrid == false){
          e.items = e.items.filter(x => x.text !== formatMessage('dxDataGrid-exportTo'))
        }
      }
    }
  }));

  return <div></div>;
});

export default ChildShowFilterRow;
