import React, { useCallback, useState, useEffect } from 'react';
import Form from 'devextreme-react/form';
import { Item } from 'devextreme-react/select-box';
import Singleselectiondropdowntable from '../../../components/dropdown-list/single-selection-dropdown-table';
import TextBox1 from '../../../components/text-box/text-box-1';
import TextBox2 from '../../../components/text-box/text-box-2';

//cookies
import Cookies from 'universal-cookie';
import { CompanyID, SearchText } from '../../../utils/default-cookies';
import './listing-header.scss';
const cookies = new Cookies();
const ListingHeader1 =  ({  dataSource, 
                            defaultValue, 
                            placeholder, 
                            captionDropdown, 
                            captionTextbox,
                            valueProp, 
                            defaultName,
                            nameProp, 
                            displayProp,
                            searchTextValue,
                            onSearchValueChange,
                            onSelectValueChange,
                            visible,
                            placeHolderText,
                            buttonText
                        }) => {

    var selectedCompanyAIG = [];
    const [companyAIGvalue, setCompanyAIGValue] = useState("");

    if(dataSource.length > 0) {
        //Cannot set triple equation
        selectedCompanyAIG =  dataSource.filter(function(data) {
            return data[valueProp] == CompanyID();
        });

        if(selectedCompanyAIG.length > 0 && companyAIGvalue === "") {
            setCompanyAIGValue(selectedCompanyAIG[0][nameProp]);
        }
    }

    const onSelectionChanged = useCallback((selection,displayProp,valueProp) => {
        setCompanyAIGValue(selection[displayProp]);
        onSelectValueChange(selection[valueProp]);
    }, [onSelectValueChange]);

    const [searchValue, setSearchValue] = useState(SearchText());   
    
    if(searchTextValue !=="" && searchValue === "")
        setSearchValue(searchTextValue);
    
    const onTbvalueChange = useCallback((value) => {
        setSearchValue(value);
        onSearchValueChange(value);
    }, [onSearchValueChange]);

    useEffect(() => {
        setSearchValue(searchValue);
    },[searchValue]);

    return(
        <React.Fragment>
            <Form  colCount={8}>
                <Item colSpan={2}>
                    <Singleselectiondropdowntable 
                        dataSource  ={dataSource} 
                        defaultValue={defaultValue}
                        defaultName ={defaultName}
                        placeholder ={placeholder}
                        caption     ={captionDropdown}
                        valueProp   ={valueProp}
                        nameProp    ={nameProp}
                        displayProp ={displayProp}
                        onSelectionChanged={onSelectionChanged}>    
                    </Singleselectiondropdowntable>
                </Item> 
                <Item colSpan={2}>
                    <TextBox1 
                        displayValue= {'value'}
                        value       = {companyAIGvalue}
                        disabled    = {true}
                        caption     = {captionTextbox}
                        stylingMode = {'filled'}>
                    </TextBox1>
                </Item>
                <Item colSpan={4}></Item>
                <Item colSpan={4}>
                    <TextBox2
                        placeHolderText = {placeHolderText}
                        buttonStyle= {'search-word'}
                        buttonText = {buttonText}
                        value={searchValue}
                        onTbvalueChange = {onTbvalueChange}
                        visible = {visible}
                    ></TextBox2>
                </Item>
            </Form>                       
        </React.Fragment>
    );
}
export default ListingHeader1;